@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-120%);
  }
}

.container {
  @apply z-40;

  @screen lg {
    @apply flex;
    flex: 1;
  }
}

.container.autoDisplay {
  @apply fixed bottom-0;
  animation: slideIn 0.8s forwards;
}

.container.autoDisplay.slideOut {
  @apply fixed bottom-0;
  animation: slideOut 0.8s forwards;
}

.container.mobile {
  @apply bottom-5  mx-5;
}

.container.mobile.slideOut {
  @apply bottom-5;
}

.container.mobile.open {
  @apply top-20  overflow-y-auto;
}

.modal {
  @screen lg {
    flex: 9;
    max-width: 31.25rem;
  }
}

.modal.fullHeight {
  @apply h-full;
}
.close {
  @apply absolute top-2.5 right-2.5  text-primaryB;
}

.container.full {
  @apply w-full;
}
@screen lg {
  .container.full {
    @apply flex justify-center relative bg-none;
  }
  .container.full .emptyDiv {
    @apply absolute w-full h-full left-0 z-0;
    background: url(/img/fond-pop-in.png) white 0 -2rem no-repeat;
  }
  .container.full .modal {
    @apply w-full z-10;
    max-width: 37.5rem;
  }
}
