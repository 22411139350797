.title {
  @apply mb-2.5 font-inter font-medium text-sm text-primaryA uppercase;
}

.animateMotion {
  @apply bg-grey50;
}

.descriptionContainer {
  padding: 0.9375rem 0.625rem;
}

.name {
  @apply font-inter text-sm font-medium text-primaryA mb-px;
  animation: appear 1s;
}

.description {
  @apply font-inter text-primaryA text-sm;
  animation: appear 1s;
}

@keyframes appear {
  from {
    @apply opacity-10;
  }
  to {
    @apply opacity-100;
  }
}
