.topContainer {
  @apply w-full flex flex-col px-2.5;

  @screen md {
    @apply grid grid-cols-12 gap-x-4 px-11;
  }

  @screen xl {
    @apply gap-x-6;
  }
}
