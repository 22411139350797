.almaWrapperWording {
  @apply flex items-start justify-end flex-col-reverse w-max p-3;

  @screen md {
    @apply flex-row flex-wrap items-center;
  }
}

.almaWording {
  font-family: 'Venn', 'sans-serif';
  @apply text-sm;
}

.almaLogo {
  filter: invert(1);
  @apply -ml-1 w-12;

  @screen md {
    @apply ml-0 mb-1;
  }
}

[dir='rtl'] .almaLogo {
  @apply -mr-1 ml-0;
  @screen md {
    @apply mr-0;
  }
}
