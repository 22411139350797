.container {
  @apply flex flex-col items-center;
  max-width: 31.25rem;
  padding: 2.1875rem 2.5rem 5.75rem 2.5rem;
}

.container.darkTheme {
  @apply bg-primaryA  text-primaryB  items-start text-left;
  padding: 6.75rem 1.5rem;
}

.container.darkTheme.fullHeight {
  @apply flex justify-center h-full text-center;
}

.title {
  @apply mb-1.5  text-2xl font-gilda leading-8  font-normal text-center;
  font-weight: 325;
}

.desc {
  @apply text-center font-lora text-sm;
  font-weight: 400;
  line-height: 1.375rem;
}

.container.darkTheme .desc {
  @apply text-center;
  @screen lg {
    @apply text-left;
  }
}

.btn {
  @apply mt-10;
  width: 45%;
}
