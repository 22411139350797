/* Mobile */

.addToListContainer {
  @apply flex flex-row gap-2.5;
}

.button {
  @apply block relative p-3 rounded-full bg-primaryB;
  box-shadow: 0 0.0625rem 0.25rem rgba(18, 18, 18, 0.1);
  z-index: 15;
  transition: all 0.3s;
}

.button.unavailable {
  @apply text-grey500 cursor-not-allowed border-grey300;
}

.button:hover {
  @apply bg-transparent border-grey300 border-0 md:border !important;
}

.button:not(.unavailable):hover {
  @apply bg-grey200 border border-primaryA;
}

.buttonLabel {
  @apply hidden;
}

.buttonIcon {
  @apply mr-0;
}
[dir='rtl'] .buttonIcon {
  @apply ml-0 mr-auto;
}

/* Desktop */

@screen md {
  .addToListContainer {
    @apply justify-between;
  }

  .button {
    @apply flex font-inter text-xs font-medium text-center box-border bg-primaryB px-4 text-primaryA border border-primaryA rounded-none justify-center items-center uppercase p-0 ml-0;
    min-height: 47px;
    width: calc(50% - 0.25rem);
    transform: unset;
    right: unset;
  }

  [dir='rtl'] .button {
    @apply mr-0 ml-auto;
  }

  .button:hover {
    @apply bg-grey200 border border-primaryA;
  }

  .buttonLabel {
    @apply block;
  }

  .buttonIcon {
    @apply w-4 h-4 mr-4;
  }

  [dir='rtl'] .buttonIcon {
    @apply ml-4 mr-0;
  }
}
