.container {
  @apply overflow-hidden;
}

.slider {
  @apply relative whitespace-nowrap;
}

.pictoContainer {
  @apply mr-2 md:mr-1 relative;

  width: 2.625rem;
  height: 2.625rem;

  border-top-left-radius: 6.25rem;
  border-top-right-radius: 6.25rem;

  transform: translateX(0px);
  transition: transform 0s ease-in-out;
}
[dir='rtl'] .pictoContainer {
  @apply ml-2 mr-0 md:ml-1 md:mr-0;
}

.pictoContainer:hover {
  @apply bg-grey50;
}

.pictoContainer.selected {
  @apply bg-grey50;

  animation: onClickPicto 0.4s;
}

.pictoContainer:after {
  @apply block;
  content: '';

  border-bottom: 1px solid #121212;

  transform: scaleX(0) translateY(1.125rem);
  transition: transform 0.3s;
}

.pictoContainer.selected:after {
  transform: scaleX(1) translateY(1.125rem);
}

.picto {
  @apply absolute top-1/2 left-1/2;
  transform: translate(-50%, -50%);
}

.next {
  @apply absolute pl-2 right-0;

  height: 2.625rem;
  background: rgba(255, 255, 255, 0.9);
}
[dir='rtl'] .next {
  @apply pl-2 pr-0;
}

.morePicto {
  @apply font-inter text-xxs leading-4;
}

.arrowRight {
  @apply inline-block;
  margin-left: 0.5625rem;
}

@keyframes onClickPicto {
  from {
    @apply bg-grey50;
  }
  to {
    @apply bg-grey50;
  }
}
