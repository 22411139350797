.container {
  @apply flex justify-center items-center w-full  py-8 mx-2.5 lg:mx-11 border-t border-primaryA;
  min-height: 20rem;
}

.content {
  @apply w-full;
  @screen md {
    width: 70%;
  }
}
