.root {
  @apply flex flex-row items-center w-full px-1 py-2 box-border border-t border-primaryA bg-primaryB;
  height: 5.125rem;
}

.loadingDots {
  @apply mx-auto;
}

.priceLine {
  @apply flex flex-col;
}

.price {
  @apply font-inter font-medium text-primaryA;
  font-size: 1.5rem;
}

.discountLine {
  @apply flex flex-row justify-between items-center;
}

.basePrice {
  @apply inline-block pb-1 font-inter text-base line-through;
}

.discount {
  @apply flex items-center justify-center ml-1.5 bg-primaryA font-inter font-medium text-base text-primaryB;
  border-radius: 0.1875rem;
  padding: 0.3125rem 0.46875rem;
}

[dir='rtl'] .discount {
  @apply mr-1.5 ml-0;
}

.basePrice + .discount {
  @apply ml-2;
}

[dir='rtl'] .basePrice + .discount {
  @apply mr-2 ml-0;
}

.button {
  @apply ml-auto flex items-center justify-center py-0;
  height: 3.0625rem;
}
[dir='rtl'] .button {
  @apply mr-auto;
  margin-left: unset;
}

.button.unavailable {
  @apply bg-grey500 cursor-not-allowed;
}

.button.unavailable:hover {
  @apply border-transparent;
}

.notifyMeIcon {
  margin-top: 0.0625rem;
  width: 1.0625rem;
  height: 0.8125rem;
}

.labelFrom {
  @apply font-normal text-sm;
}
