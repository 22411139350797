/* purgecss start ignore */

.PageView_container__NCuqn {
  padding: 0;
}

.PageView_withTopSeparator__kkqqF {
  padding-top: 1.25rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Form_container__gA321 {
  white-space: pre-line;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .Form_container__gA321 {
    padding-bottom: 1.75rem;
  }
}

.Form_container__gA321.Form_darkTheme__A15yv {
  background-color: var(--primaryA);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--primaryB);
}

@media (min-width: 992px) {
  .Form_container__gA321.Form_darkTheme__A15yv {
    padding-bottom: 0.625rem;
  }
}

.Form_container__gA321.Form_darkTheme__A15yv {
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.2);
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}

.Form_darkTheme__A15yv {
  background-color: var(--primaryA);
  color: var(--primaryB);
}

.Form_title__uJ_Gm {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  text-align: center;
  font-family: Gilda Display, serif;
  font-size: 2.125rem;
  line-height: 2.625rem;
  font-weight: 325;
}

.Form_title__uJ_Gm.Form_darkTheme__A15yv {
  line-height: 2rem;
  font-size: 1.5rem
}

@media (min-width: 992px) {

.Form_title__uJ_Gm.Form_darkTheme__A15yv {
    text-align: left
}
  }

.Form_desc__J1S8r {
  text-align: center;
  font-family: Lora, serif;
  font-size: .875rem;
  line-height: 1.125rem;
  line-height: 1.375rem;
}

@media (min-width: 992px) {

.Form_desc__J1S8r.Form_darkTheme__A15yv {
    text-align: left
}
  }

.Form_miniDesc__VqTI4 {
  text-align: center;
  font-family: Lora, serif;
  font-size: .6875rem;
  line-height: 1.125rem;
  margin-top: 0.625rem;
}

@media (min-width: 992px) {

.Form_miniDesc__VqTI4.Form_darkTheme__A15yv {
    text-align: left
}
  }

.Form_smiley__VvLHL {
  font-style: normal;
}

.Form_italicText___pDWm {
  font-style: italic;
}

.Form_form__dltZ9 {
  margin-top: 1.25rem;
}

.Form_select__1mvQT {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.Form_inlineInputs__3eTkd {
  display: flex;
  width: 100%;
  flex-direction: column
}

@media (min-width: 992px) {

.Form_inlineInputs__3eTkd {
    flex-direction: row;

    align-items: flex-start
}
  }

@media (min-width: 992px) {

.Form_inlineInputs__3eTkd > :first-child {
    width: 100%;

    margin-right: 0.75rem
}
  }

@media (min-width: 992px) {

.Form_inlineInputs__3eTkd > :last-child {
    width: 100%;

    margin-left: 0.75rem
}
  }

.Form_radioInput__kyCOZ > button {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
}

.Form_labelField__CSl_k {
  margin-bottom: 0.25rem;
  display: block;
  font-size: 0.875rem
}

@media (min-width: 992px) {

.Form_labelField__CSl_k {
    font-size: 0.6875rem;

    margin-bottom: 0px;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}
  }

.Form_field__Qkq8U {
  margin-left: 0.25rem;
  height: 3rem;
  width: 100%;
  border-width: 1px;
  border-color: var(--grey300)
}

@media (min-width: 992px) {

.Form_field__Qkq8U {
    grid-column: span 2 / span 2;

    margin-left: 0px;

    height: 2.5rem
}
  }

[dir='rtl'] .Form_field__Qkq8U {
  margin-right: 0.25rem;
  margin-left: 0px
}

@media (min-width: 992px) {

[dir='rtl'] .Form_field__Qkq8U {
    margin-right: 0px
}
  }

.Form_errorInput__1ByP0 {
  border-width: 1px;
  border-style: solid;
  border-color: var(--negative);
}

.Form_titleField__Kql2x {
  width: 7rem;
  background-color: transparent;
}

.Form_selectField__8BZSO {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.Form_btnPosition__8gjIX {
  margin-top: 1.5rem;
  display: inline-block;
  width: 100%
}

@media (min-width: 992px) {

.Form_btnPosition__8gjIX {
    margin-top: 0.625rem
}
  }

/* mt-0 et w-auto pour le bouton de la page de confirmation en dark theme */

@media (min-width: 992px) {

.Form_inlineButton__sJCVa {
    margin-top: 0px;

    width: auto
}
  }

.Form_btn__egiDN {
  width: 100%;
}

.Form_btn__egiDN.Form_darkTheme__A15yv {
  background-color: var(--primaryB);
  color: var(--primaryA);
}

.Form_btn__egiDN:hover.Form_darkTheme__A15yv {
  border-color: var(--primaryB);
  background-color: var(--primaryA);
  color: var(--primaryB);
}

@media (min-width: 992px) {

.Form_submissionLine__rVBSQ {
    position: relative;

    display: flex;

    align-items: center
}
  }

@media (min-width: 992px) {

.Form_submissionLine__rVBSQ > :first-child {
    width: 85%
}
  }

.Form_alertContainer__iwkLI {
  margin-bottom: 1rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Success_container__Co4c8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31.25rem;
  padding: 2.1875rem 2.5rem 5.75rem 2.5rem;
}

.Success_container__Co4c8.Success_darkTheme__xHHK3 {
  align-items: flex-start;
  background-color: var(--primaryA);
  text-align: left;
  color: var(--primaryB);
  padding: 6.75rem 1.5rem;
}

.Success_container__Co4c8.Success_darkTheme__xHHK3.Success_fullHeight__r7Bir {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.Success_title__35e19 {
  margin-bottom: 0.375rem;
  text-align: center;
  font-family: Gilda Display, serif;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 400;
  line-height: 2rem;
  font-weight: 325;
}

.Success_desc__e7wi1 {
  text-align: center;
  font-family: Lora, serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.Success_container__Co4c8.Success_darkTheme__xHHK3 .Success_desc__e7wi1 {
  text-align: center
}

@media (min-width: 992px) {

.Success_container__Co4c8.Success_darkTheme__xHHK3 .Success_desc__e7wi1 {
    text-align: left
}
  }

.Success_btn__0EfI4 {
  margin-top: 2.5rem;
  width: 45%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Error_container__xJdex {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31.25rem;
  padding: 2.1875rem 2.5rem 5.75rem 2.5rem;
}

.Error_container__xJdex.Error_darkTheme__FWOIN {
  align-items: flex-start;
  background-color: var(--primaryA);
  text-align: left;
  color: var(--primaryB);
  padding: 6.75rem 1.5rem;
}

.Error_container__xJdex.Error_darkTheme__FWOIN.Error_fullHeight__WvS_J {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.Error_title__YAQ0K {
  margin-bottom: 0.375rem;
  text-align: center;
  font-family: Gilda Display, serif;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 400;
  line-height: 2rem;
  font-weight: 325;
}

.Error_desc__FBFX3 {
  text-align: center;
  font-family: Lora, serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.Error_container__xJdex.Error_darkTheme__FWOIN .Error_desc__FBFX3 {
  text-align: center
}

@media (min-width: 992px) {

.Error_container__xJdex.Error_darkTheme__FWOIN .Error_desc__FBFX3 {
    text-align: left
}
  }

.Error_btn__w56Gw {
  margin-top: 2.5rem;
  width: 45%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AlreadySubscribed_container__d_Rg5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31.25rem;
  padding: 2.1875rem 2.5rem 5.75rem 2.5rem;
}

.AlreadySubscribed_container__d_Rg5.AlreadySubscribed_darkTheme__J9szw {
  align-items: flex-start;
  background-color: var(--primaryA);
  text-align: left;
  color: var(--primaryB);
  padding: 6.75rem 1.5rem;
}

.AlreadySubscribed_container__d_Rg5.AlreadySubscribed_darkTheme__J9szw.AlreadySubscribed_fullHeight__2Oedz {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.AlreadySubscribed_title__sbMpO {
  margin-bottom: 0.375rem;
  text-align: center;
  font-family: Gilda Display, serif;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 400;
  line-height: 2rem;
  font-weight: 325;
}

.AlreadySubscribed_desc__1lyem {
  text-align: center;
  font-family: Lora, serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.AlreadySubscribed_container__d_Rg5.AlreadySubscribed_darkTheme__J9szw .AlreadySubscribed_desc__1lyem {
  text-align: center
}

@media (min-width: 992px) {

.AlreadySubscribed_container__d_Rg5.AlreadySubscribed_darkTheme__J9szw .AlreadySubscribed_desc__1lyem {
    text-align: left
}
  }

.AlreadySubscribed_btn__k0X7x {
  margin-top: 2.5rem;
  width: 45%;
}

/* purgecss end ignore */

/* purgecss start ignore */

@keyframes NewsletterModal_slideIn__ENgMU {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes NewsletterModal_slideOut__rjaUq {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-120%);
  }
}

.NewsletterModal_container__KEeIJ {
  z-index: 40
}

@media (min-width: 992px) {

.NewsletterModal_container__KEeIJ {
    flex: 1 1;

    display: flex
}
  }

.NewsletterModal_container__KEeIJ.NewsletterModal_autoDisplay__2JFj8 {
  position: fixed;
  bottom: 0px;
  animation: NewsletterModal_slideIn__ENgMU 0.8s forwards;
}

.NewsletterModal_container__KEeIJ.NewsletterModal_autoDisplay__2JFj8.NewsletterModal_slideOut__rjaUq {
  position: fixed;
  bottom: 0px;
  animation: NewsletterModal_slideOut__rjaUq 0.8s forwards;
}

.NewsletterModal_container__KEeIJ.NewsletterModal_mobile__GOlzI {
  bottom: 1.25rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.NewsletterModal_container__KEeIJ.NewsletterModal_mobile__GOlzI.NewsletterModal_slideOut__rjaUq {
  bottom: 1.25rem;
}

.NewsletterModal_container__KEeIJ.NewsletterModal_mobile__GOlzI.NewsletterModal_open__1id_q {
  top: 5rem;
  overflow-y: auto;
}

@media (min-width: 992px) {

.NewsletterModal_modal__U7bIa {
    flex: 9 1;

    max-width: 31.25rem
}
  }

.NewsletterModal_modal__U7bIa.NewsletterModal_fullHeight__Yj_lw {
  height: 100%;
}

.NewsletterModal_close__1ZH_8 {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  color: var(--primaryB);
}

.NewsletterModal_container__KEeIJ.NewsletterModal_full__KCo8N {
  width: 100%;
}

@media (min-width: 992px) {
  .NewsletterModal_container__KEeIJ.NewsletterModal_full__KCo8N {
    position: relative;
    display: flex;
    justify-content: center;
    background-image: none;
  }

  .NewsletterModal_container__KEeIJ.NewsletterModal_full__KCo8N .NewsletterModal_emptyDiv__S3ZD4 {
    position: absolute;
    left: 0px;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: url(/img/fond-pop-in.png) white 0 -2rem no-repeat;
  }

  .NewsletterModal_container__KEeIJ.NewsletterModal_full__KCo8N .NewsletterModal_modal__U7bIa {
    z-index: 10;
    width: 100%;
    max-width: 37.5rem;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.NewsletterView_container__TPpBv {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (min-width: 992px) {
  .NewsletterView_container__TPpBv {
    margin-bottom: -2.5rem;
  }
}

.NewsletterView_container__TPpBv {
  background-image: url('/img/smallable-bg-newsletter.jpg');
}

.NewsletterView_content__VP2fK {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  height: auto;
  width: auto;
  background-color: var(--primaryB);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (min-width: 992px) {
  .NewsletterView_content__VP2fK {
    z-index: 10;
    margin-top: 5rem;
    margin-bottom: 5rem;
    padding-bottom: 0.75rem;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

/* Mobile */

.AddToListButtons_addToListContainer__QmzKH {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
}

.AddToListButtons_button__zQC7S {
  position: relative;
  display: block;
  border-radius: 9999px;
  background-color: var(--primaryB);
  padding: 0.75rem;
  box-shadow: 0 0.0625rem 0.25rem rgba(18, 18, 18, 0.1);
  z-index: 15;
  transition: all 0.3s;
}

.AddToListButtons_button__zQC7S.AddToListButtons_unavailable__T9nUh {
  cursor: not-allowed;
  border-color: var(--grey300);
  color: var(--grey500);
}

.AddToListButtons_button__zQC7S:hover {
  border-width: 0px !important;
  border-color: var(--grey300) !important;
  background-color: transparent !important;
}

@media (min-width: 768px) {
  .AddToListButtons_button__zQC7S:hover {
    border-width: 1px !important;
  }
}

.AddToListButtons_button__zQC7S:not(.AddToListButtons_unavailable__T9nUh):hover {
  border-width: 1px;
  border-color: var(--primaryA);
  background-color: var(--grey200);
}

.AddToListButtons_buttonLabel__7GoYj {
  display: none;
}

.AddToListButtons_buttonIcon__WJ7XW {
  margin-right: 0px;
}

[dir='rtl'] .AddToListButtons_buttonIcon__WJ7XW {
  margin-right: auto;
  margin-left: 0px;
}

/* Desktop */

@media (min-width: 768px) {
  .AddToListButtons_addToListContainer__QmzKH {
    justify-content: space-between;
  }

  .AddToListButtons_button__zQC7S {
    margin-left: 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    border-width: 1px;
    border-color: var(--primaryA);
    background-color: var(--primaryB);
    padding: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    font-family: inter, sans-serif;
    font-size: .6875rem;
    line-height: 1.125rem;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--primaryA);
    min-height: 47px;
    width: calc(50% - 0.25rem);
    transform: unset;
    right: unset;
  }

  [dir='rtl'] .AddToListButtons_button__zQC7S {
    margin-right: 0px;
    margin-left: auto;
  }

  .AddToListButtons_button__zQC7S:hover {
    border-width: 1px;
    border-color: var(--primaryA);
    background-color: var(--grey200);
  }

  .AddToListButtons_buttonLabel__7GoYj {
    display: block;
  }

  .AddToListButtons_buttonIcon__WJ7XW {
    margin-right: 1rem;
    height: 1rem;
    width: 1rem;
  }

  [dir='rtl'] .AddToListButtons_buttonIcon__WJ7XW {
    margin-right: 0px;
    margin-left: 1rem;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.AlmaBanner_almaWrapperWording__6pb7O {
  display: flex;
  width: -moz-max-content;
  width: max-content;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0.75rem
}

@media (min-width: 768px) {

.AlmaBanner_almaWrapperWording__6pb7O {
    flex-direction: row;

    flex-wrap: wrap;

    align-items: center
}
  }

.AlmaBanner_almaWording__xfx_1 {
  font-family: 'Venn', 'sans-serif';
  font-size: .875rem;
  line-height: 1.125rem;
}

.AlmaBanner_almaLogo__HJPJe {
  filter: invert(1);
  margin-left: -0.25rem;
  width: 3rem
}

@media (min-width: 768px) {

.AlmaBanner_almaLogo__HJPJe {
    margin-bottom: 0.25rem;

    margin-left: 0px
}
  }

[dir='rtl'] .AlmaBanner_almaLogo__HJPJe {
  margin-right: -0.25rem;
  margin-left: 0px
}

@media (min-width: 768px) {

[dir='rtl'] .AlmaBanner_almaLogo__HJPJe {
    margin-right: 0px
}
  }

/* purgecss end ignore */

/* purgecss start ignore */

.ProductPaymentsMethods_klarnaPlacement__soiXP {
  margin-bottom: 0.625rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--grey100);
}

.ProductPaymentsMethods_almaPlacement__YCGAx {
  margin-bottom: 0.625rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--grey100);
}

.ProductPaymentsMethods_klarnaPlacement__soiXP div::part(osm-container) {
  border-style: none;
}

.ProductPaymentsMethods_paypalDisclaimer__g2yA_ {
  border-width: 1px;
  border-style: solid;
  border-color: var(--grey100);
  padding: 0.75rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.StickyBottomLine_root__lBXew {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-top-width: 1px;
  border-color: var(--primaryA);
  background-color: var(--primaryB);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 5.125rem;
}

.StickyBottomLine_loadingDots__DD9VE {
  margin-left: auto;
  margin-right: auto;
}

.StickyBottomLine_priceLine__Oew_9 {
  display: flex;
  flex-direction: column;
}

.StickyBottomLine_price__nQP6v {
  font-family: inter, sans-serif;
  font-weight: 500;
  color: var(--primaryA);
  font-size: 1.5rem;
}

.StickyBottomLine_discountLine__iJTHw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.StickyBottomLine_basePrice__jGpyC {
  display: inline-block;
  padding-bottom: 0.25rem;
  font-family: inter, sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
  text-decoration: line-through;
}

.StickyBottomLine_discount__zurtR {
  margin-left: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryA);
  font-family: inter, sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 500;
  color: var(--primaryB);
  border-radius: 0.1875rem;
  padding: 0.3125rem 0.46875rem;
}

[dir='rtl'] .StickyBottomLine_discount__zurtR {
  margin-right: 0.375rem;
  margin-left: 0px;
}

.StickyBottomLine_basePrice__jGpyC + .StickyBottomLine_discount__zurtR {
  margin-left: 0.5rem;
}

[dir='rtl'] .StickyBottomLine_basePrice__jGpyC + .StickyBottomLine_discount__zurtR {
  margin-right: 0.5rem;
  margin-left: 0px;
}

.StickyBottomLine_button__ChVuF {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 3.0625rem;
}

[dir='rtl'] .StickyBottomLine_button__ChVuF {
  margin-right: auto;
  margin-left: unset;
}

.StickyBottomLine_button__ChVuF.StickyBottomLine_unavailable__vpIub {
  cursor: not-allowed;
  background-color: var(--grey500);
}

.StickyBottomLine_button__ChVuF.StickyBottomLine_unavailable__vpIub:hover {
  border-color: transparent;
}

.StickyBottomLine_notifyMeIcon__y8ikW {
  margin-top: 0.0625rem;
  width: 1.0625rem;
  height: 0.8125rem;
}

.StickyBottomLine_labelFrom__sbRbh {
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ProductGreenableMenu_container__qO9aU {
  overflow: hidden;
}

.ProductGreenableMenu_slider__nzw_E {
  position: relative;
  white-space: nowrap;
}

.ProductGreenableMenu_pictoContainer__tdEo2 {
  position: relative;
  margin-right: 0.5rem;
}

@media (min-width: 768px) {
  .ProductGreenableMenu_pictoContainer__tdEo2 {
    margin-right: 0.25rem;
  }
}

.ProductGreenableMenu_pictoContainer__tdEo2 {
  width: 2.625rem;
  height: 2.625rem;
  border-top-left-radius: 6.25rem;
  border-top-right-radius: 6.25rem;
  transform: translateX(0px);
  transition: transform 0s ease-in-out;
}

[dir='rtl'] .ProductGreenableMenu_pictoContainer__tdEo2 {
  margin-right: 0px;
  margin-left: 0.5rem;
}

@media (min-width: 768px) {
  [dir='rtl'] .ProductGreenableMenu_pictoContainer__tdEo2 {
    margin-right: 0px;
    margin-left: 0.25rem;
  }
}

.ProductGreenableMenu_pictoContainer__tdEo2:hover {
  background-color: var(--grey50);
}

.ProductGreenableMenu_pictoContainer__tdEo2.ProductGreenableMenu_selected__R5mc8 {
  background-color: var(--grey50);
  animation: ProductGreenableMenu_onClickPicto__U4KqP 0.4s;
}

.ProductGreenableMenu_pictoContainer__tdEo2:after {
  display: block;
  content: '';
  border-bottom: 1px solid #121212;
  transform: scaleX(0) translateY(1.125rem);
  transition: transform 0.3s;
}

.ProductGreenableMenu_pictoContainer__tdEo2.ProductGreenableMenu_selected__R5mc8:after {
  transform: scaleX(1) translateY(1.125rem);
}

.ProductGreenableMenu_picto__w7hk4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ProductGreenableMenu_next__UOIFM {
  position: absolute;
  right: 0px;
  padding-left: 0.5rem;
  height: 2.625rem;
  background: rgba(255, 255, 255, 0.9);
}

[dir='rtl'] .ProductGreenableMenu_next__UOIFM {
  padding-right: 0px;
  padding-left: 0.5rem;
}

.ProductGreenableMenu_morePicto__irVyi {
  font-family: inter, sans-serif;
  font-size: .625rem;
  line-height: 1.125rem;
  line-height: 1rem;
}

.ProductGreenableMenu_arrowRight___gB0i {
  display: inline-block;
  margin-left: 0.5625rem;
}

@keyframes ProductGreenableMenu_onClickPicto__U4KqP {
  from {
    background-color: var(--grey50);
  }

  to {
    background-color: var(--grey50);
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.ProductGreenable_title__NCiZg {
  margin-bottom: 0.625rem;
  font-family: inter, sans-serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--primaryA);
}

.ProductGreenable_animateMotion__EufxY {
  background-color: var(--grey50);
}

.ProductGreenable_descriptionContainer__hr9jQ {
  padding: 0.9375rem 0.625rem;
}

.ProductGreenable_name__5IQdv {
  margin-bottom: 1px;
  font-family: inter, sans-serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 500;
  color: var(--primaryA);
  animation: ProductGreenable_appear__g2F1d 1s;
}

.ProductGreenable_description__e9ENZ {
  font-family: inter, sans-serif;
  font-size: .875rem;
  line-height: 1.125rem;
  color: var(--primaryA);
  animation: ProductGreenable_appear__g2F1d 1s;
}

@keyframes ProductGreenable_appear__g2F1d {
  from {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.TrustMultiSource_root__n98O0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 100% !important;
}

@media (min-width: 992px) {
  .TrustMultiSource_root__n98O0 {
    padding-top: 1rem !important;
  }
}

.TrustMultiSource_container__2cCv8 {
  border-color: var(--primaryA);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.TrustMultiSource_placeholder__hU3z7 {
  padding-top: 0.5rem;
  font-family: inter, sans-serif;
  font-size: .6875rem;
  line-height: 1.125rem;
}

@media (min-width: 992px) {
  .TrustMultiSource_placeholder__hU3z7 {
    padding-top: 0px;
  }
}

.TrustMultiSource_trustpilot-widget__csBK4 {
  padding-top: 0.5rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ProductActions_alertContainer__r9nLb {
  margin-top: 1rem;
}

.ProductActions_selectorsContainer__lpE8i {
  margin-top: 1.5rem
}

@media (min-width: 768px) {

.ProductActions_selectorsContainer__lpE8i {
    margin-top: 1rem
}
  }

.ProductActions_selector__y_ph4 + .ProductActions_selector__y_ph4 {
  margin-top: 0.625rem;
}

.ProductActions_productSizeGuide__RC5TZ {
  margin-top: 0.75rem;
  border-bottom-width: 1px;
  border-color: var(--primaryA);
  padding-bottom: 1px;
  font-size: .875rem;
  line-height: 1.125rem;
}

@media (min-width: 768px) {
  .ProductActions_productSizeGuide__RC5TZ {
    margin-top: 0.5rem;
  }
}

.ProductActions_productSizeGuide__RC5TZ {
  color: var(--primaryA);
}

.ProductActions_deliveryInfo__7v4uL {
  margin-top: 1.5rem;
}

.ProductActions_actions__6Qy_X {
  margin-top: 1rem;
  display: none;
}

@media (min-width: 768px) {
  .ProductActions_actions__6Qy_X {
    display: block;
  }
}

.ProductActions_action__Rbvgt {
  display: flex;
  width: 100%;
  white-space: nowrap;
}

.ProductActions_action__Rbvgt.ProductActions_unavailable__lnhIT {
  cursor: not-allowed;
  background-color: var(--grey500);
}

.ProductActions_action__Rbvgt.ProductActions_unavailable__lnhIT:hover {
  border-width: 0px;
}

.ProductActions_notifyMeAction__D6Gl9 {
  font-size: 0.75rem;
}

.ProductActions_notifyMeIcon__Nkjvd {
  width: 1.5rem;
  height: 1rem;
}

.ProductActions_listButtonsDesktop__IwaYu {
  margin-top: 0.5rem;
  display: none;
}

@media (min-width: 768px) {
  .ProductActions_listButtonsDesktop__IwaYu {
    display: flex;
  }
}

.ProductActions_notSellable__cBO_r {
  display: none
}

@media (min-width: 768px) {

.ProductActions_notSellable__cBO_r {
    margin-top: 2rem;

    display: flex;

    font-family: inter, sans-serif;

    font-size: .6875rem;

    line-height: 1.125rem
}
  }

.ProductActions_loading__Wkcm0 {
  display: flex;
  height: 2.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .ProductActions_loading__Wkcm0 {
    height: 3rem;
  }
}

.ProductActions_stickyBottomLine__Ln0vw {
  position: sticky;
  bottom: 0px;
}

@media (min-width: 768px) {
  .ProductActions_stickyBottomLine__Ln0vw {
    display: none;
  }
}

.ProductActions_stickyBottomLine__Ln0vw {
  z-index: 25;
}

.ProductActions_productActionsDetails__6_V7M {
  display: flex;
  flex-wrap: wrap;
}

.ProductActions_productGreenable__T_JD8 {
  order: 2;
  margin-top: 1.5rem;
}

.ProductActions_productDescription__4fk7d {
  order: 1;
  margin-top: 0.75rem;
  margin-bottom: 1.5rem
}

@media (min-width: 768px) {
  .ProductActions_productDescription__4fk7d {
    margin-bottom: 0px;
  }
}

@media (min-width: 577px) {

.ProductActions_productDescription__4fk7d {
    order: 3;
}
  }

.ProductActions_paymentsMethods__PRs_6 {
  order: 3;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  width: 100%
}

@media (min-width: 577px) {

.ProductActions_paymentsMethods__PRs_6 {
    order: 1
}
  }

@keyframes ProductActions_fade-in-out__urMrP {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.PriceLine_container__GDvTj {
  display: flex;
  flex-direction: column;
}

.PriceLine_priceLine__qS4Hc {
  display: flex;
  height: 2.75rem;
  flex-direction: row;
  align-items: center;
  font-family: inter, sans-serif;
  font-size: 1.375rem;
}

.PriceLine_priceLine__qS4Hc > * + * {
  margin-left: 0.625rem;
}

[dir='rtl'] .PriceLine_priceLine__qS4Hc > * + * {
  margin-right: 0.625rem;
  margin-left: 0px;
}

.PriceLine_basePrice__mnKCV {
  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--grey600);
  text-decoration: line-through;
}

.PriceLine_price__g_kMA {
  font-weight: 400;
  color: var(--primaryA);
}

.PriceLine_discount__wOcSw {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primaryA);
  font-family: inter, sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 500;
  color: var(--primaryB);
  border-radius: 0.1875rem;
  padding: 0.3125rem 0.46875rem;
}

.PriceLine_labelFrom__4guW7 {
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
}

/* purgecss end ignore */

/* purgecss start ignore */

.PriceBlock_almaPlacement__ULJgE {
  margin-left: -0.75rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.SalerInformation_container__KnnA0 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-width: 2px;
  border-color: var(--primaryA);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.SalerInformation_container__KnnA0 p {
  font-family: Lora, sans-serif;
  font-size:  0.8125rem;
  line-height: 1.125rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.SalerInformation_container__KnnA0.SalerInformation_lite___X7pf {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-width: 1px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.SalerInformation_container__KnnA0.SalerInformation_lite___X7pf p {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  font-size: .6875rem;
  line-height: 1.125rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ProductDetails_container__cQFkj {
  position: relative;
  margin-top: 1.25rem
}

@media (min-width: 768px) {

.ProductDetails_container__cQFkj {
    grid-column-start: 9;

    grid-column-end: 13;

    margin-top: 0px;

    padding-top: 1rem
}
  }

@media (min-width: 768px) {
  .ProductDetails_isSticky__mNhOd {
    position: sticky;
    top: 0px;
    align-self: flex-start;
    position: -webkit-sticky;
  }
}

.ProductDetails_tagline__yWS0C {
  margin-bottom: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProductDetails_listButtonsMobile__wVSCz {
  margin-bottom: 0.5rem;
  margin-left: auto;
}

@media (min-width: 768px) {
  .ProductDetails_listButtonsMobile__wVSCz {
    display: none;
  }
}

[dir='rtl'] .ProductDetails_listButtonsMobile__wVSCz {
  margin-right: auto;
}

.ProductDetails_titleContainer__ZA8TH {
  display: flex;
  width: 100%
}

@media (min-width: 1201px) {

.ProductDetails_titleContainer__ZA8TH {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
}
  }

.ProductDetails_title__meFUp {
  font-family: inter, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  color: var(--primaryA);
}

@media (min-width: 768px) {
  .ProductDetails_title__meFUp {
    grid-column: span 3 / span 3;
  }
}

.ProductDetails_title__meFUp {
  font-size: 1.625rem;
}

.ProductDetails_title__meFUp a {
  text-transform: uppercase;
}

.ProductDetails_name__8xYtn {
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 1rem;
  font-family: inter, sans-serif;
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: 400;
}

@media (min-width: 768px) {
  .ProductDetails_name__8xYtn {
    grid-column: span 3 / span 3;
  }
}

@media (min-width: 992px) {
  .ProductDetails_name__8xYtn {
    padding-bottom: 0px;
  }
}

.ProductDetails_priceBlock__78t8H {
  display: none
}

@media (min-width: 768px) {

.ProductDetails_priceBlock__78t8H {
    display: flex;

    flex-direction: column
}
  }

.ProductDetails_loading__NZRL0 {
  display: flex;
  height: 2.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 992px) {
  .ProductDetails_loading__NZRL0 {
    height: 3rem;
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.ProductPresentation_topContainer__IcMQx {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 0.625rem;
  padding-right: 0.625rem
}

@media (min-width: 768px) {

.ProductPresentation_topContainer__IcMQx {
    display: grid;

    grid-template-columns: repeat(12, minmax(0, 1fr));

    grid-column-gap: 1rem;

    -moz-column-gap: 1rem;

         column-gap: 1rem;

    padding-left: 2.75rem;

    padding-right: 2.75rem
}
  }

@media (min-width: 1201px) {

.ProductPresentation_topContainer__IcMQx {
    -moz-column-gap: 1.5rem;
         column-gap: 1.5rem
}
  }

/* purgecss end ignore */

/* purgecss start ignore */

.SearchView_loader__j34Iz {
  margin: auto;
  display: flex;
  height: 20rem;
  width: 20rem;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  text-align: center;
}

/* purgecss end ignore */

/* purgecss start ignore */

.ShipupTracking_container__MPdvV {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-color: var(--primaryA);
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 992px) {
  .ShipupTracking_container__MPdvV {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
}

.ShipupTracking_container__MPdvV {
  min-height: 20rem;
}

.ShipupTracking_content__MHEGn {
  width: 100%
}

@media (min-width: 768px) {

.ShipupTracking_content__MHEGn {
    width: 70%
}
  }

/* purgecss end ignore */

/* purgecss start ignore */

.OrderTrackingView_container__stVKs {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px;
}

/* purgecss end ignore */

