.container {
  @apply flex flex-col;
}

.priceLine {
  @apply h-11 flex flex-row items-center font-inter;
  font-size: 1.375rem;
}

.priceLine > * + * {
  @apply ml-2.5;
}

[dir='rtl'] .priceLine > * + * {
  @apply mr-2.5 ml-0;
}

.basePrice {
  @apply text-base text-grey600 line-through;
}

.price {
  @apply text-primaryA font-normal;
}

.discount {
  @apply flex items-center justify-center bg-primaryA font-inter font-medium text-base text-primaryB;
  border-radius: 0.1875rem;
  padding: 0.3125rem 0.46875rem;
}

.labelFrom {
  @apply font-normal text-sm;
}
