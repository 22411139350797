.container {
  @apply my-5 py-3 px-2 border-2 border-primaryA;
}
.container p {
  @apply py-1 typo-lora-text-m;
}

.container.lite {
  @apply my-2 py-1.5 px-1 border;
}
.container.lite p {
  @apply py-0.5 text-xs;
}
