.alertContainer {
  @apply mt-4;
}

.selectorsContainer {
  @apply mt-6;
  @screen md {
    @apply mt-4;
  }
}
.selector + .selector {
  @apply mt-2.5;
}

.productSizeGuide {
  @apply border-b border-primaryA mt-3 pb-px text-sm md:mt-2;
  color: var(--primaryA);
}

.deliveryInfo {
  @apply mt-6;
}

.actions {
  @apply hidden mt-4 md:block;
}
.action {
  @apply flex w-full whitespace-nowrap;
}
.action.unavailable {
  @apply bg-grey500 cursor-not-allowed;
}

.action.unavailable:hover {
  @apply border-0;
}

.notifyMeAction {
  composes: action;
  font-size: 0.75rem;
}
.notifyMeIcon {
  width: 1.5rem;
  height: 1rem;
}

.listButtonsDesktop {
  @apply hidden md:flex mt-2;
}

.notSellable {
  @apply hidden;

  @screen md {
    @apply mt-8 flex font-inter text-xs;
  }
}

.loading {
  @apply w-full h-10 lg:h-12 flex items-center justify-center;
}

.stickyBottomLine {
  @apply sticky md:hidden bottom-0;
  z-index: 25;
}

.productActionsDetails {
  @apply flex flex-wrap;
}

.productGreenable {
  @apply order-2 mt-6;
}

.productDescription {
  @apply order-1 mt-3 mb-6 md:mb-0;

  @screen sm {
    @apply order-3;
  }
}

.paymentsMethods {
  @apply w-full order-3 mt-2.5 mb-2.5;

  @screen sm {
    @apply order-1;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
