.root {
  @apply w-full lg:pt-4 my-0 !important;
}

.container {
  @apply pt-2 pb-2 border-primaryA;
}
.placeholder {
  @apply text-xs pt-2 lg:pt-0 font-inter;
}
.trustpilot-widget {
  @apply pt-2;
}
