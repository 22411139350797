.container {
  @apply relative mt-5;

  @screen md {
    @apply col-start-9 col-end-13 mt-0 pt-4;
  }
}
@screen md {
  .isSticky {
    @apply self-start sticky top-0;
    position: -webkit-sticky;
  }
}

.tagline {
  @apply flex justify-between items-center mb-2.5;
}

.listButtonsMobile {
  @apply mb-2 md:hidden ml-auto;
}

[dir='rtl'] .listButtonsMobile {
  @apply mr-auto;
}

.titleContainer {
  @apply w-full flex;

  @screen xl {
    @apply gap-x-6;
  }
}

.title {
  @apply font-inter font-medium text-primaryA text-3xl md:col-span-3;
  font-size: 1.625rem;
}
.title a {
  @apply uppercase;
}
.name {
  @apply block pt-1 pb-4 font-inter font-normal text-base md:col-span-3 lg:pb-0;
}

.priceBlock {
  @apply hidden;

  @screen md {
    @apply flex flex-col;
  }
}

.loading {
  @apply w-full h-10 lg:h-12 flex items-center justify-center;
}
