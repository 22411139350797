.klarnaPlacement {
  @apply mb-2.5 border border-solid border-grey100;
}

.almaPlacement {
  @apply mb-2.5 border border-solid border-grey100;
}

.klarnaPlacement div::part(osm-container) {
  @apply border-none;
}

.paypalDisclaimer {
  @apply p-3 border border-solid border-grey100;
}
