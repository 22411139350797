.container {
  @apply px-10 whitespace-pre-line pb-5 lg:pb-7;
}

.container.darkTheme {
  @apply px-6 pb-5 pt-5 lg:pb-2.5 text-primaryB bg-primaryA;
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.2);
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}

.darkTheme {
  @apply text-primaryB bg-primaryA;
}

.title {
  @apply font-gilda text-center my-2.5;
  font-size: 2.125rem;
  line-height: 2.625rem;
  font-weight: 325;
}

.title.darkTheme {
  line-height: 2rem;
  font-size: 1.5rem;
  @screen lg {
    @apply text-left;
  }
}

.desc {
  @apply text-center font-lora text-sm;
  line-height: 1.375rem;
}

.desc.darkTheme {
  @screen lg {
    @apply text-left;
  }
}

.miniDesc {
  @apply text-center font-lora text-xs;
  margin-top: 0.625rem;
}

.miniDesc.darkTheme {
  @screen lg {
    @apply text-left;
  }
}

.smiley {
  @apply not-italic;
}

.italicText {
  @apply italic;
}

.form {
  @apply mt-5;
}

.select {
  @apply my-2.5;
}

.inlineInputs {
  @apply w-full flex flex-col;
  @screen lg {
    @apply flex-row items-start;
  }
}

.inlineInputs > :first-child {
  @screen lg {
    width: 100%;
    margin-right: 0.75rem;
  }
}

.inlineInputs > :last-child {
  @screen lg {
    width: 100%;
    margin-left: 0.75rem;
  }
}

.radioInput > button {
  @apply w-full mx-0;
}

.labelField {
  @apply block mb-1;
  font-size: 0.875rem;
  @screen lg {
    @apply mb-0 py-2;
    font-size: 0.6875rem;
  }
}

.field {
  @apply w-full h-12 border border-grey300 ml-1;
  @screen lg {
    @apply col-span-2 h-10 ml-0;
  }
}

[dir='rtl'] .field {
  @apply mr-1 ml-0;
  @screen lg {
    @apply mr-0;
  }
}

.errorInput {
  @apply border border-solid border-negative;
}

.titleField {
  @apply bg-transparent w-28;
}

.selectField {
  composes: field;
  @apply px-3;
}

.btnPosition {
  @apply mt-6 w-full inline-block;
  @screen lg {
    @apply mt-2.5;
  }
}

/* mt-0 et w-auto pour le bouton de la page de confirmation en dark theme */
.inlineButton {
  @screen lg {
    @apply mt-0 w-auto;
  }
}
.btn {
  @apply w-full;
}

.btn.darkTheme {
  @apply bg-primaryB text-primaryA;
}

.btn:hover.darkTheme {
  @apply bg-primaryA text-primaryB border-primaryB;
}

.submissionLine {
  @screen lg {
    @apply relative flex items-center;
  }
}
.submissionLine > :first-child {
  @screen lg {
    width: 85%;
  }
}

.alertContainer {
  @apply mb-4;
}
